.title {
  color: #939393;
  font-size: 1.6em;
  margin: 1em 0;
  padding-bottom: 0.5em;
  border-bottom: 1px dotted #ccc;
}

.chatbox {
  border: 1px solid #ccc;
  max-height: 500px;
  min-height: 20px;
  margin: 0 0 1em 0;
  overflow: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.chat-item {
  padding: 0.5em 1em;
  font-size: 0.9em;

  &:not(:first-child) {
    border-top: 1px dotted #ccc;
  }

  &:nth-child(even) {
    background: #f3f6ff;
  }
}

  .chat-user {
    font-weight: 700;
    margin-right: 1em;

    .sub-icon {
      margin-right: 5px;
    }

    .sub {
      color: #3dac63;
    }
  }

  .chat-message {
    display: inline-block;
    margin-bottom: 0;

    [data-action] & {
      display: inline-block;
      text-transform: capitalize;
    }

    [data-action="try"] & {
      color: #ba242b;
      font-weight: 700;
      font-size: 1.2em;
      text-transform: uppercase;
      animation: blinker 2s linear infinite;

      &:after {
        content: "!!!";
      }
    }
  }

.message-box {
  margin-top: 10px;
  display: flex;
  border-radius: 6px;
}

.addon {
  border: 1px solid #ddd;
  display: block;
  display: flex;
  align-items: center;
  padding: 0 0.5em;

  &--btn {
    cursor: pointer;
    color: #fff;
    background: #171f39;
  }
}

.actions {
  margin-top: 1em;
  padding-top: 5px;
  border-top: 1px dotted #344782;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Mod Tools
.mod {
  padding: 5px 0;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.mod-btn {
  cursor: pointer;
  color: #fff;
  font-size: 0.6em;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  background: red;
  padding: 0.2em 0.5em;
  margin-right: 5px;

  &-warn {
    color: #000;
    background: #ff9970;
  }

}

.uid {
  font-size: 0.6em;
  font-family: monospace;
  display: inline-block;
  margin-left: 10px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
