@use '~sass-mq/_mq';

.title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;

  &--link {
    font-weight: 400;
    color: #fff;
    font-size: 0.8em;
    background: #3dac63;
    border-radius: 4px;
    cursor: pointer;
    padding: 0.3em 0.5em;
  }
}

.list-item {
  color: #171f39;
  display: flex;
  flex-direction: column;
  transition: 0.5s all;
  text-align: center;

  &:hover {
    color: #ba242b;
    text-decoration: none;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dbdbdb;
  }

  @include mq.mq($until:tablet) {
    img {
      display: none;
    }
  }
}

.fixture-type {
  font-weight: 900;
  text-transform: capitalize;
}

.date {
  font-size: .8em;
}

.date p {
  color: #000;
  font-size: 1em;
  margin-bottom: 0;
}

.fixture {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 0px;
  padding: 1em 0.5em;
}

.fixture-info {
  background: #f7f7f7;
  border-bottom: 1px solid darken(#f7f7f7, 10%);
  padding: 1em;
  display: flex;
  justify-content: center;
  font-size: 0.8em;
}

.seperator {
  display: inline-block;
  margin: 0 10px;
}

.fixture-type {
  font-weight: 700;
}

.type {
  border-top: 1px solid #8f1c21;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
}

  .type-title {
    color: #fff;
    font-size: 0.7em;
    font-weight: 300;
    border-radius: 4px;
    padding: 0 5px;
    text-transform: uppercase;
    background: #ba242b;
    margin-top: -9px;
  }

  .list-item-date {
    flex: 0 25%;
    margin: 0;

    p {
      color: #171f39;
      margin: 0;
    }

    &-live {
      color: #ba242b;
      font-weight: bold;
      animation: blinker 3s linear infinite;
    }
  }

  .list-item-team {
    font-weight: 500;
    font-size: 1em;
    flex: 0 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      margin: 0 10px;
    }
  }

  .home {
    justify-content: flex-start;
    text-align: left;
  }

  .away {
    justify-content: flex-end;
    text-align: right;
  }

.fixture-live {
  color: #ba242b!important;
  font-weight: bold;
  animation: blinker 3s linear infinite;
}

.match-score {
  font-weight: 900;
  font-size: 1.2em;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
