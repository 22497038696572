@use '~sass-mq/_mq';

.account {
  border-top: 1px solid #ccc;
  padding: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mq.mq($until:tablet) {
    flex-direction: column;
  }

  h3 {
    font-weight: 700;
    font-size: 0.9em;
    margin: 0;
  }

  p {
    margin-bottom: 8px;
  }
}

.mcp {
  color: #219cc5;
  border-bottom: 1px dotted #219cc5;
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
}

.action-link {
  font-size: 0.9em;
  display: inline-block;
  padding: 0.2em 0.8em;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #ccc;
  transition: 0.5s all;
  margin-right: 5px;

  @include mq.mq($until:tablet) {
    margin-top: 5px;
  }

  &--highlight {
    color: #fff;
    background: #ba242b;

    &:hover {
      color: #fff!important;
      background: darken(#ba242b, 10%);
    }
  }

  &:hover {
    color: #ba242b;
  }
}

.btn-group {
  margin: 1em 0;
  display: inline-flex;
}

// Dropdown
.dropdown {
  display: inline-block;
  position: relative;
  margin-left: 1em;
  border-bottom: 1px dotted #212c5e;
}

.dropdown-links {
  background: #fff;
  display: none;
  position: absolute;
  width: 250%;
  padding: 1em;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.active {
  display: block;
}

.dropdown-link {
  list-style: none;
}
