@use '~sass-mq/_mq';

.container {
  margin: 0 auto;

  @include mq.mq($from: desktop) {
    max-width: 1024px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(1, 1fr);

  @include mq.mq($from: wide) {
    grid-template-columns: repeat(2, 1fr);
  }
}
