.header {
  background: #212c5e;
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-logo {
  display: inline-block;

  &:focus {
    background-color: unset;
  }
}

.nav {
  margin-bottom: 1em;
  background: #f0f0f0;
  border-bottom: 1px solid #ccc;
  padding: 0 2em;
}
