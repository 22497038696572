.panel {
  background-image: linear-gradient(180deg, #F5515F 0%, #9F041B 100%);
  padding: 1.5em;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 2px 0 rgb(0 0 0 / 12%);
  height: fit-content;

  p {
    color: #fff;
    margin: 0;
  }
}

.details {
  color: #fff;
  text-align: center;
  font-size: 0.8em;
}

  .details-team {
    font-weight: 700;
  }

  .details-type {
    opacity: 0.8;
    text-transform: uppercase;
  }

.board {
  color: #fff;
  list-style: none;
}

  .board-item {
    color: #fff;
    font-size: 2em;
    margin: 1em 0;
    list-style: none;
  }

  .team {
    display: flex;
    justify-content: space-between;
  }

  .scorers {
    padding-top: 1em;
  }

  .board-score {
    color: #000;
    background: #fff;
    font-weight: 700;
    border-radius: 6px;
    padding: 0 0.6em;
  }
