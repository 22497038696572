.panel {
  border: 1px solid #979797;
}

.panel-header {
  color: #fff;
  background: #011b51;
  border-bottom: 1px solid #979797;
  text-align: center;
  padding: 1em 0;
}

.panel-select {
  color: #fff;
  font-size: 12px;
  width: auto;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}
