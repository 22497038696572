.reply {
  display: flex;
}

.input {
  border-radius: 0;
}

.btn {
  cursor: pointer;
  color: #fff;
  background: #1095c1;
  max-height: 50px;
  padding: 0 1em;
  display: flex;
  align-items: center;
  transition: 0.5s all;

  &:hover {
    background: darken(#1095c1, 10%);
  }
}

.actions {
  background: #1095c11c;
  font-size: .9em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5em 1em;
  margin: 0;
  align-items: center;
  border: 1px solid #ccc;
  border-bottom: 0;
}

.action-item {
  list-style: none;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-right: 0.5em;
  }
}

.action-btn {
  cursor: pointer;
  text-transform: capitalize;
  border: 1px solid #ccc;
  background: #fff;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  transition: 0.5s all;

  &:hover {
    background: none;
  }

  &-disabled {
    color: #ccc;
    cursor: not-allowed;
  }
}
