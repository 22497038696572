@use '~sass-mq/_mq';

.footer {
  border-top: 1px solid #ccc;
  margin-top: 3em;
  padding: 2em;
  background: #f9f9f9;
  text-align: center;
}

.link-item {
  list-style: none;

  @include mq.mq($from: tablet) {
    display: inline-block;
    margin: 0 10px;
  }
}
