.editor-container {
  .section {
    margin: 0;
  }
}

.rc-md-editor {
  .button-type-table,
  .button-type-image,
  .button-type-code-inline,
  .button-type-code-block,
  .button-type-wrap {
    display: none!important;
  }
}
