@use '~sass-mq/_mq';

.header {
  min-height: 172px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
}

.teams {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq.mq($until:tablet) {
    flex-direction: column;
  }
}

.team-name {
  font-size: 1.8em;
  margin: 0 10px;

  @include mq.mq($until:tablet) {
    font-size: 1.2em;
  }
}

.seperator {
  display: inline-block;
  margin: 0 5px;
}
