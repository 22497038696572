.remove {
  display: block;
  margin: 0;
  background: #e1f8ff;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.7em;
  text-align: center;

  a {
    font-weight: 700;
  }
}
