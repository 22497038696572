.title {
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 1em;
}

.content {
  line-height: 1.7em;
  font-size: 0.9em;
}

.form {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1em;
  margin: 1em 0;
}
